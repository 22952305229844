.intro {
  margin-bottom: 15px;
}

.download-button {
  font-size: 16px;
  margin-top: 10px;
  height: 38px;
  background: #1992a0;
  border: #1992a0;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .download-button {
    width: 300px;
    white-space: break-spaces;
    height: 100%;
  }
}