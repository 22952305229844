.atv-rio-intro {
  margin-bottom: 15px;
}

.atv-rio-paragraph {
  margin-bottom: 25px;
}

.atv-rio-label {
  text-align: center;
  margin-top: 0px;
  font-size: 13px;
}

.download-button {
  font-size: 16px;
  margin-top: 10px;
  height: 38px;
  background: #1992a0;
  border: #1992a0;
  margin-bottom: 10px;
}

a.ant-btn {
  padding-top: 4px !important;
}

.rio-img {
  max-width: 400px;
}

.atv-big-img {
  max-width: 600px;
}

.buttons-wrap {
  display: flex;
  flex-wrap: wrap;
}

.button-list {
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .download-button {
    width: 300px;
    white-space: break-spaces;
    height: 100%;
  }

  .atv-img {
    max-width: 100%;
  }

  .rio-img {
    max-width: 100%;
  }
  
  .atv-big-img {
    max-width: 100%;
  }
}