.logo-home-wrapper {}

.text-home-wrapper {}

.livro-logo-home {
  max-width: 600px;
  margin-top: 50px;
}

.home-title {
  text-align: right !important;
  padding: 15px 0px;
}

.home-text {
  text-align: justify;
  font-size: 16px;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .livro-logo-home {
    width: 300px;
    margin-top: 50px;
  }
}