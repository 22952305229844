.atv-cap2-intro {
  margin-bottom: 15px;
}

a.ant-btn {
  padding-top: 4px !important;
}

.download-button {
  font-size: 16px;
  margin-top: 10px;
  height: 38px;
  background: #1992a0;
  border: #1992a0;
  margin-bottom: 10px;
}

.cap2-img {
  max-width: 400px;
}

.cap2-img-col {
  margin-left: 10px;
}

video {
  margin: 15px 0px;
}

.video-col {
  display: flex;
}

@media screen and (max-width: 600px) {
  .download-button {
    width: 300px;
    white-space: break-spaces;
    height: 100%;
  }

  .cap2-img-col {
    margin-left: 0px;
  }

  .cap2-img {
    max-width: 100%;
  }

  .video-col {
    flex-direction: column;
  }
}