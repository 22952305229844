.atv-intro-text {
  text-align: justify;
  font-size: 16px;
  margin-top: 10px;
}

.page-title {
  text-align: justify;
}

.atv-intro-img {
  margin: 15px 0px;
}

@media screen and (max-width: 600px) {
  .atv-intro-img {
    width: 100%;
  }
}