.atv-cap6-intro {
    margin-bottom: 15px;
  }
  
  .download-button {
    font-size: 16px;
    margin-top: 10px;
    height: 38px;
    background: #1992a0;
    border: #1992a0;
    margin-bottom: 10px;
  }
  
  a.ant-btn {
    padding-top: 4px !important;
  }
  
  .cap6-img {
    max-width: 400px;
  }
  
  .cap6-img-col {
    margin-left: 10px;
  }
  
  @media screen and (max-width: 600px) {
    .download-button {
      width: 300px;
      white-space: break-spaces;
      height: 100%;
    }
  
    .cap6-img-col {
      margin-left: 0px;
    }

    .cap6-img {
      max-width: 100%;
    }
  }