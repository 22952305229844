.capacitacao-intro {
    margin-bottom: 15px;
}

.cap-img {
    max-width: 1000px;
}

.cap-video {
    margin: 15px 0px;
}

@media screen and (max-width: 600px) {
    .cap-img {
        max-width: 100%;
    }
}