.ant-layout-sider-children {
  background: #036281;
  background-image: url("../../assets/fundo_agua.png");
}

.ant-menu {
  background: transparent;
  border: none;
  font-weight: bold;
  color: #036281;

  overflow-y: auto;
  overflow-x: hidden;
  height: 70%;
}

/* .ant-menu::-webkit-scrollbar {
  display: none;
} */

.ant-menu a {
  color: #036281 !important;
}

.ant-menu-submenu-arrow {
  color: #036281;
}

.livro-logo-menu-wrapper {
  background: #f0f2f5;
  display: flex;
  justify-content: center;

  height: 15%;
}

.livro-logo-menu {
  padding: 5px 25px;
  height: 150px;
}

.ant-menu-inline .ant-menu-item {
  width: 100%;
  background-color: #ffffff87;
}

.ant-menu-inline .ant-menu-item::after {
  border-color: #92D271;
}

.ant-menu-item-selected {
  color: #92D271;
}

.ant-menu-item-selected a {
  color: #92D271 !important;
}

.ant-menu-sub.ant-menu-inline {
  background: transparent;
}

.ant-menu-submenu-selected a {
  color: #92D271;
}

.ant-menu-submenu-selected {
  color: #92D271;
}

.ant-menu-submenu-title {
  background-color: #ffffff87;
}

.ant-layout-header {
  background-image: url("../../assets/fundo_agua.png");
  display: flex;
  align-items: center;
}

.logos-wrapper {
  width: 250px;
  display: flex;  
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;

  height: 10%;
  margin-left: 10px;
}

.logo-extra {
  width: 70px;
}

@media screen and (max-width: 600px) {
  .ant-layout-content {
    padding: 90px 30px 30px 30px;
  }

  .ant-drawer-header {
    background: transparent;
  }

  .ant-drawer-header-title {
    justify-content: end;
  }

  .anticon-close {
    color: white;
    font-size: 24px;
  }

  .ant-drawer-content {
    background-image: url("../../assets/fundo_agua.png");
  }

  .ant-btn {
    background: transparent;
    border: none;
  }

  .svg-inline--fa.fa-w-14 {
    font-size: 24px;
    color: white;
  }

  .logos-wrapper {
    padding: 10px 0px;
    width: 90%;
  }
}