@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url(./assets/calibri-regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Calibri !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-title {
  font-size: 22px;
  font-weight: bold;
  /* color: #92D271; */
  color: #345305;
}

.page-subtitle {
  margin-top: 20px;
}

.page-subtitle-small {
  font-size: 20px;
}

.atv-text {
  text-align: justify;
  font-size: 16px;
  margin-top: 10px;
}

.atv-ico {
  width: 50px;
}

.atv-img {
  margin: 15px 0px;
}

.material-list {
  text-align: left;
}

.how-to {
  list-style: decimal;
  text-align: left;
}

.music-title {
  font-weight: bold;
  font-size: 16px;
  color: #345305;
  margin-top: 20px;
}

.music-composition {
  margin-bottom: 10px;
}

.music-refrao {
  margin: 10px 0px;
}

@media screen and (max-width: 600px) {
  .atv-img {
    width: 100%;
  }  
}