.quem {
  text-align: left;
}

.quem-title {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}

.quem-initial-text {
  text-align: justify;
  padding: 10px 0px;
}

.quem-photo-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.quem-photo-card {
  background: #92d27187;
  padding: 10px;
  margin-right: 15px;
  margin-top: 10px;
}

.quem-photo {
  width: 300px;
}

.quem-photo-text {
  width: 300px;
  text-align: justify;
  padding: 10px 0px;
}

.quem-photo-name {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  max-width: 300px;
}
