.making-intro {
  margin-bottom: 15px;
}

.making-photo-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.making-photo-card {
  background: #92d27187;
  padding: 10px;
  margin-right: 15px;
  margin-top: 10px;
}

.making-photo {
  width: 500px;
}

.making-photo-text {
  width: 300px;
  text-align: justify;
  padding: 10px 0px;
}

video {
  margin: 0px;
}

@media screen and (max-width: 600px) {
  .making-photo {
    max-width: 100%;
    width: 100%;
  }

  video {
    max-width: 100%;
    width: 100%;
  }  
}